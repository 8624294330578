.processContainer {
  width: 100%;

  display: flex;
  height: max-content;
  align-items: center;
  padding-right: 10rem;
  padding-top: 5rem;
  padding-left: 10rem;
}
.left-p {
  flex: 1 1;
  height: 100%;
  justify-content: space-between;
}
.right-p {
  height: 45rem;

  flex: 1 1;
  position: relative;
}
.right-p > img {
  position: absolute;
  right: 0;
  object-fit: cover;
  border-radius: 5px;
  width: 90%;
  height: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.left-p .mainHeading {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #060210d5;
  font-family: "myFontBold";

  margin-bottom: 2.5rem;
}
.cardHolder {
  display: flex;
  margin-top: 2.5rem;
  flex-direction: column;
  height: max-content;
}
.card {
  padding-right: 7rem;
  display: flex;
}
.card .count > h3::after {
  content: "";
  display: block;
  width: 5px;
  margin: 1rem 0;
  border-radius: 10px;
  background-color: #5a6e79;
  height: 4rem;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.card .count > h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card .count > h3 > span {
  border-radius: 50%;
  padding: 0.9rem;
  font-size: 1.1rem;
  font-weight: bold;
  width: 3rem;
  height: 3rem;
  color: white;
  background-color: #5a6e79;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.card .count {
  height: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.text > span {
  font-size: 1.3rem;
  font-weight: bolder;
  color: #060210d5;

  margin-top: 0.9rem;
  margin-bottom: 1rem;
}
.text > span:last-child {
  font-size: 0.9rem;
  font-weight: normal;
  line-height: 1.4rem;
  color: #060210d5;
  margin-bottom: 3rem;
}

/* Media S fror small devices */
@media screen and (max-width: 700px) {
  .processContainer {
    padding: 0;
    flex-direction: column;
  }
  .left-p {
    padding: 1rem;
  }
  .left-p .mainHeading {
    font-size: 2rem;
    margin-top: 1rem;
  }
  .card {
    padding: 0;
  }
  .card .count > h3::after {
    height: 6rem;
  }
  .right-p {
    flex: unset;
    width: 100%;
    height: 30rem;
  }
  .right-p > img {
    width: 100%;
    border-radius: 0;
  }
}
