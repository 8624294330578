.container {
  width: 100%;
  height: 6rem;
  padding-right: 10rem;
  padding-left: 10rem;
  z-index: 50;
  align-items: center;
  position: relative;
  display: flex;
  top: 0;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 1px 0px #99a2ad;
  justify-content: space-between;
}
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "myFontBold";

  color: #000;
}
.links {
  display: flex;
  gap: 2rem;

  justify-content: space-around;
}
.link {
  text-decoration: none;
  color: #99a2ad;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.5rem;
  transition: all 300ms linear;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
}
.link:hover {
  color: #020202;
}
.container > div:last-child {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.container > div:last-child img {
  height: 2.6rem;

  padding: 0.4rem;
}
.container > div:last-child img:last-child {
  padding: 0.2rem;
}
/* Responsive css */
@media screen and (max-width: 700px) {
  .container {
    width: 100%;
    padding: 1rem;
  }
  .links {
    position: absolute;
    display: none;
  }
}
