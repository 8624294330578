.benefitsSection {
  width: 100%;
  padding: 3rem 10rem;
}
.title {
  font-size: 2rem;
  font-weight: bolder;
  width: 40rem;
  display: flex;
  font-family: "myFontBold";
  margin-top: 4rem;
  color: #060210;
  align-items: center;
}
.title::before {
  content: "";
  display: block;
  background-color: #060210;
  width: 6.5rem;
  margin-right: 2rem;
  height: 0.5rem;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.benefits {
  width: 100%;
  display: flex;
  gap: 3.5rem;
  padding: 3rem 0;

  justify-content: space-between;
}

.benefitItem {
  display: flex;
  justify-content: baseline;
}
.benefitItem > img {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1rem;
}
.benefitItem .benefitItemText {
  display: flex;
  flex-direction: column;
}

.benefitItem .benefitItemText span:first-child {
  margin-bottom: 2rem;
  color: #060210;
  font-size: 1.3rem;
  font-weight: bold;
}
.benefitItem .benefitItemText span:last-child {
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: #060210d5;
}
/* Responsive benefit */
@media screen and (max-width: 700px) {
  .benefitsSection {
    padding: 1rem;
  }
  .title {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
  .title::before {
    margin-bottom: 1.5rem;
  }
  .benefits {
    flex-direction: column;
  }
}
