.exerienceSection {
  width: 100%;
  padding-left: 10rem;
  display: flex;
  margin-bottom: 3rem;
  padding-right: 10rem;
  align-items: center;
}
.left-e {
  flex: 1 1;
}
.left-e > img {
  width: 85%;
  object-fit: cover;
  height: 40rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.right-e {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.right-e > h1 {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #060210;
  font-family: "myFontBold";

  margin-bottom: 2.5rem;
}
.right-e > p {
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: #060210d5;
  margin-bottom: 3rem;
}
.numbers {
  display: flex;
  align-items: center;
}
.numbers > div {
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
}
.numbers > div > span:first-child {
  font-size: 3.2rem;
  font-weight: bolder;
  color: #5a6e79;
  font-family: "myFontBold";
}
.numbers > div > span:last-child {
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: #060210d5;
  margin-top: 1rem;
}
.buttonMore {
  display: flex;
  width: 12rem;
  margin-top: 3rem;
  height: 3.2rem;
  position: relative;
  align-items: center;
}
.buttonMore > button {
  all: unset;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #5a6e79;
  border: 3px solid #5a6e79;
}
.buttonMore > img {
  width: 3rem;
  position: absolute;
  right: -2.3rem;
}
/* Media S fror small devices */
@media screen and (max-width: 700px) {
  .exerienceSection {
    margin: 0;
    padding: 0;
    align-items: flex-start;
    flex-direction: column;
  }
  .left-e {
  }

  .left-e > img {
    width: 100%;
    border-radius: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
  .right-e {
    padding: 1rem;
    width: 100%;
  }
  .right-e > h1 {
    font-size: 2rem;
  }
  .numbers {
    justify-content: space-between;
    gap: 2rem;
  }
  .numbers > div {
    margin: 0;
  }
  .numbers > div > span:first-child {
    font-size: 2.5rem;
  }
  .buttonMore {
    width: 10rem;
  }
  .buttonMore > img {
    width: 2.6rem;
  }
}
