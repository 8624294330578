.footlinksSection {
  display: flex;
  height: max-content;
  padding-top: 4.2rem;
  padding-bottom: 5rem;
  justify-content: baseline;
}
.f-logo {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "myFontBold";

  color: white;
}
.footlinksSection > div:first-child > h3 {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.873);
  margin-top: 2rem;
  padding-right: 5rem;
  width: 20rem;
  line-height: 1.6rem;
}
.footlinksSection > div:last-child {
  display: flex;
  justify-content: space-between;
}

.footlinksSection > div:last-child > div {
  display: flex;
  justify-content: baseline;
  flex-direction: column;
}
.footlinksSection > div:last-child > div > h1 {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: white;
  font-weight: bold;
  padding-right: 10rem;
}
.footlinksSection > div > div > a:hover {
  color: rgb(248, 25, 25);
  cursor: pointer;
}
.footlinksSection > div:last-child > div > a:hover {
  color: blue !important;
}
.footlinksSection > div:last-child > div > a {
  /* all: unset; */
  z-index: 10;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.705);
  margin-bottom: 1rem;
}
.footlinksSection > div:last-child > div:last-child > div {
  display: flex;
  margin-top: 3rem;
}
.footlinksSection > div:last-child > div:last-child > div > img {
  width: 2.5rem;
  margin-right: 1.5rem;
  padding: 0.2rem;
  height: 2.5rem;
}
.footlinksSection > div:last-child > div:last-child > h1 {
  width: max-content;
}
.footlinksSection > div:last-child > div:last-child > p {
  color: rgba(255, 255, 255, 0.872);
  margin-bottom: 1rem;
}
.li:hover {
  font-size: 1rem;

  color: #fff;
  cursor: pointer !important;
}
/* Responsive css */
@media screen and (max-width: 700px) {
  .footlinksSection {
    flex-direction: column;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }
  .footlinksSection > div:last-child {
    margin-top: 2rem;
    flex-direction: column;
  }
  .footlinksSection > div:last-child > div {
    margin-top: 2rem;
  }

  .f-logo {
    margin-top: 1.5rem;
  }
  .footlinksSection > div:last-child > div > a {
    /* color: blue !important; */
  }
  .footlinksSection > divlast-child > div > h1 {
    margin-bottom: 1rem;
  }
  .footlinksSection > div:last-child > div:last-child > div {
  }
}
