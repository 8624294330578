.footerSection {
  width: 100%;
  position: relative;
  height: 100%;
}

.footerSection > h1 {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #060210;
  font-family: "myFontBold";
  text-align: center;
  margin-bottom: 2.5rem;
}
.footerSection > h2 {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4rem;
  color: #060210d5;
  width: 30%;
  margin: auto;
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.emailHolder {
  padding-right: 10rem;
  position: relative;
  width: 40rem;
  height: 4rem;
  border-radius: 5px;
  align-items: center;
  z-index: 13;
  margin: auto;
  background-color: white;
  display: flex;
  box-shadow: 0px 0px 15px 0px #5a6e79;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.hackish {
  bottom: 0;
  height: 2rem;
  width: 100%;
  z-index: -30;
  background-color: #5a6e79;

  margin-top: -2rem;
}

.emailHolder > input {
  all: unset;
  position: absolute;
  width: 100%;
  padding-left: 2rem;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}
.emailHolder > button {
  background-color: red;
}
.emailHolder > button {
  all: unset;
  border-radius: 5px;

  width: 10rem;
  height: 3.5rem;
  position: absolute;
  right: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-family: "myFontBold";
  font-size: 1rem;
  background-color: #5a6e79;
}
.footerSection > div:last-child {
  height: max-content;
  position: relative;
  padding-right: 10rem;
  z-index: -2;
  padding-left: 10rem;
  background-color: #5a6e79;
}
/* Responsive css */
@media screen and (max-width: 700px) {
  .footerSection > h1 {
    padding: 1rem;
    font-size: 2rem;
  }
  .footerSection > h2 {
    width: 100%;
    padding: 1rem;
  }
  .emailHolder {
    padding: 1rem;
    width: 90%;
  }
  .footerSection > div:last-child {
    padding: 1rem;
  }
}
