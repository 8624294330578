.reviewSection {
  width: 100%;
  margin-top: 4rem;
  height: 100%;
  padding-right: 10rem;
  padding-left: 10rem;
}
.reviewSection > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.reviewSection > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.reviewSection > div:first-child > span {
  font-size: 2.5rem;
  font-weight: bolder;
  color: #060210d5;

  font-family: "myFontBold";
}
.reviewSection > div:first-child > div {
  display: flex;
  gap: 1rem;
}
.reviewSection > div:first-child > div > img {
  width: 3rem;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.03);
  padding: 0.8rem;
  height: 3rem;
}

.reviewSection > div:first-child > div > img:last-child {
  background-color: rgba(128, 128, 128, 0.13);
  padding: 0.7rem;
}
.cardsHolderr {
  width: 100%;
  height: max-content;
  display: flex;
  gap: 4rem;
  margin-top: 3rem;
  justify-content: space-between;
}
.cardd {
  background-color: #5a6e79;
  border-radius: 5px;
  width: 50%;
  display: flex;
  padding: 2.2rem;
  flex-direction: column;
  height: max-content;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.cardd > img {
  width: 3rem;
  background-color: rgba(128, 128, 128, 0.03);
  margin-bottom: 2rem;
  height: 3rem;
}

.cardd {
  font-size: 1.8rem;

  font-weight: bolder;
  color: white;
  font-family: "myFontBold";

  margin-bottom: 2.5rem;
}
.textC {
  font-size: 1rem;
  font-family: "myFont";

  color: white;
  margin-top: 2rem;

  line-height: 1.5rem;
  margin-bottom: 2.5rem;
}
.author {
  align-items: center;
  font-family: "myFont";

  display: flex;
}
.author > img {
  width: 3rem;
  margin-right: 1rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}
.details {
  display: flex;
  flex-direction: column;
}
.details > span:first-child {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.details > span:last-child {
  font-size: 0.9rem;
  color: #ffffffdd;
}
/* Responsive query  */
@media screen and (max-width: 700px) {
  .reviewSection {
    padding: 1rem;
    margin: 0;
    margin-top: 1rem;
  }
  .reviewSection > div:first-child > span {
    font-size: 2rem;
  }
  .reviewSection > div:first-child > div > img {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.6rem;
  }
  .cardsHolderr {
    gap: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }
  .cardd {
    margin-bottom: 1rem;
    padding: 1.5rem;
    width: 100%;
  }
}
