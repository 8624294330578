.heroSection {
  width: 100%;
  margin: auto;
  position: relative;
  height: fit-content;
}
.mainHero {
  height: 90vh;
  padding-top: 7rem;

  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.mainHero > img:first-child {
  width: 70%;
  margin-top: 3rem;
  object-fit: cover;
  right: 0;
  z-index: -1;
  border-radius: 5px;
  height: 80vh;
  position: absolute;
  margin-right: 10rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.artHolder {
  width: 70%;
  margin-top: 3rem;
  object-fit: cover;
  right: 0;

  height: 80vh;
  position: absolute;

  margin-right: 10rem;
}
.artHolder > div:first-child {
  width: 6rem;
  height: 5rem;
  position: absolute;
  z-index: -3;
  bottom: -1rem;
  border-radius: 5px;
  left: -1rem;
  border: 4px solid #5a6e79;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.artHolder > div:last-child {
  width: 6.5rem;
  height: 5rem;
  border-radius: 5px;

  position: absolute;
  bottom: -1rem;
  right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: #5a6e79;
}
.artHolder > div:last-child > img {
  width: 3rem;
  padding: 0.4rem;
}

.heroText {
  width: 35%;
  height: max-content;
  display: flex;
  z-index: 2;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  flex-direction: column;
  padding: 3rem 10rem;
  background-color: #5a6e79;
  color: white;
}
.heroText > span:first-child {
  font-size: 2.2rem;
  line-height: 3.5rem;
  font-family: "myFontBold";
}
.heroText > span:last-child {
  font-size: 1rem;
  padding-top: 1rem;
}
.imgTrans {
  width: 5rem;
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: -1rem;
}
.heroControls {
  margin-right: 7rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heroControls > img {
  width: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.03);
  margin-top: 1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.heroControls > div {
  background-color: #5a6e79;
  width: 1.5px;
  margin: 1rem 0;
  height: 3rem;
}
.heroControls > span {
  font-size: 1rem;
  color: #5a6e79;
  font-weight: bolder;
}
.counter {
  margin-top: 2rem;
}
/* Responsive codes small phone  */
@media screen and (max-width: 700px) {
  .mainHero {
    flex-direction: column;
    position: relative;
    height: max-content;
    padding-top: 0;
  }
  .mainHero > img:first-child {
    position: relative;
    width: 100%;
    height: 90vh;
    border-radius: 0;
    margin-right: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .artHolder {
    margin-right: 1rem;
    width: 100%;
  }
  .artHolder > div:first-child {
    width: 4.5rem;
    height: 4rem;
  }
  .artHolder > div:last-child {
    width: 4.5rem;
    height: 4rem;
    right: 0;
  }
  .heroText {
    position: absolute;
    margin-top: 8rem;
    width: 90%;
    left: 0;
    background-color: #5a6e79d2;

    padding: 2rem 2rem 2rem 1rem;
  }
  .heroControls {
    position: absolute;
    bottom: 1rem;
    margin: 0;
    left: 1rem;
  }
}
/* Responsive codes big screen  */
