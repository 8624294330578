@font-face {
  font-family: myFont;
  src: url(./assets/fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: myFontBold;
  src: url(./assets/fonts/Montserrat-Bold.otf);
}
* {
  /* outline: 1px solid red; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: myFont;
}

::-webkit-scrollbar {
  display: none;
}
.App {
  width: 100%;
  overflow-x: hidden;
}
